<template>
  <v-main style="padding: 0%">
    <v-card height="100%">
      <v-card-title style="padding: 0%">
        <v-toolbar style="padding-left: 0.3vw" dense>
          All Notifications from:
          <a
            style="
              margin-left: 0.5%;
              max-width: 20vw;
              font-size: 1em;
              vertical-align: middle;
              max-height: 80%;
            "
          >
            {{ this.companyName }}
          </a>
          <a style="margin-left: 2vw"> HoneypotSettings:</a>
          <a style="font-size: small"> {{ this.honeypotsettings }}</a>
          <v-btn
            style="position: absolute; top: 5; right: 0"
            icon
            @click="getAllMessages()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card class="pa-4">
        <v-data-table
          height="100%"
          :headers="headers"
          :items="items"
          :search="search"
          v-model:page="page"
          :items-per-page="12"
          @page-count="handlePageCount"
          ><template v-slot:item.data="{ item }">
            <div class="d-flex flex-row flex-gap-10 align-self-start">
              <div
                v-for="entry in Object.entries(item.data)
                  .filter((x) => x[0] != 'action')
                  .filter((x) => x[0] != 'timestamp')"
                :key="entry"
              >
                <div>{{ entry[0] }}: {{ entry[1] }} &nbsp;</div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-main>
</template>

<script>
export default {
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(value) {
        this.page = value;
      },
    },
  },
  data() {
    return {
      error: null,
      success: null,
      companyName: null,
      honeypotsettings: null,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      headers: [
        {
          title: "ID",
          align: "start",
          value: "id",
          sortable: true,
        },
        { title: "Type", align: "start", value: "type", sortable: true },
        { title: "Message", align: "start", value: "data", sortable: true },
        {
          title: "TimeStamp",
          align: "start",
          value: "timeStamp",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  mounted() {
    console.log("mounted triggered");
    if (
      this.$store.getters.getToken == "" ||
      this.$store.getters.getToken == null
    ) {
      console.log("getting token from sessionStorage");
      this.$store.commit("setToken", sessionStorage.getItem("jwtToken"));
    }
    console.log(this.$store.getters.getToken);
    if (
      this.$store.getters.getPot == "" ||
      this.$store.getters.getPot == null
    ) {
      console.log("getting pot from sessionstorage");
      this.$store.commit(
        "setPot",
        JSON.parse(sessionStorage.getItem("ChosenPot"))
      );
    }
    console.log(this.$store.getters.getPot);
    this.companyName = this.$store.getters.getPot.License.Company.name;
    this.getAllMessages();
    this.getHoneypotSettings();
  },
  methods: {
    handlePageCount(count) {
      this.pageCount = count;
    },
    handlePageChange(page) {
      this.page = page;
    },
    getAllMessages() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get(
          "https://shest.sboss.nl:8002/messages/all/" +
            this.$store.getters.getPot.license
        )
        .then(function (response) {
          if (response.status == 200) {
            let genitems = response.data;
            console.log(genitems);
            console.log("Before timestamp change");
            genitems.forEach((item) => {
              item.timeStamp = comp.formatTimestamp(item.timeStamp);
            });
            console.log("After timestamp change");
            comp.items = genitems.sort((a, b) => b.id - a.id);
            console.log("After sort");
            console.log(comp.items);
            comp.pageCount = comp.items.length / comp.itemsPerPage + 1;
          } else {
            comp.error = "Response was not as expected!";
          }
        })
        .catch(function () {
          comp.error = "Error loading messages!";
        });
    },
    formatTimestamp(timestamp) {
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };

      const formattedDate = new Date(timestamp).toLocaleDateString(
        "en-US",
        options
      );
      return formattedDate.replace(",", ""); // Remove comma after the day
    },
    getHoneypotSettings() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get(
          "https://shest.sboss.nl:8002/honeypotsettings/" +
            this.$store.getters.getPot.license
        )
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            if (
              response.data.honeypotsetting == "" ||
              response.data.honeypotsetting == null
            ) {
              comp.honeypotsettings =
                "No settings in database! use V1.88 or newer";
            } else {
              comp.honeypotsettings = response.data.honeypotsetting;
            }
          } else {
            comp.error = "Response was not as expected!";
          }
        })
        .catch(function () {
          comp.error = "Error loading messages!";
        });
    },
  },
};
</script>
